import React, { useState } from "react"
import styled from "@emotion/styled"
import { injectIntl } from "gatsby-plugin-intl"
import ReactPlayer from "react-player"

const MatelasHomeVideoBannerWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  position: relative;
  flex-direction: column;
  color: #262626;
  padding: 0;
`

const TopSection = styled.div`
  display: flex;
  background: #d7edf6;
  width: 100%;
  align-items: center;
  position: relative;
  cursor: pointer;
  color: #fff;

  :hover {
    color: #fff;
  }
`

const AbsoluteButton = styled.a`
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0px;
  left: 0px;
  cursor: pointer;
  z-index: 2;

  @media (max-width: 600px) {
    display: none;
  }
`

const MobileAbsoluteButton = styled.a`
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0px;
  left: 0px;
  cursor: pointer;
  z-index: 2;
  display: none;

  @media (max-width: 600px) {
    display: block;
  }
`

const ButtonTitle = styled.span`
  display: none;
`

const DesktopVideoWrapper = styled.div`
  width: 100%;
  display: flex;
  overflow: hidden;
  aspect-ratio: 12/5;

  > div {
    position: absolute;
    top: 0px;
    left: 0px;
    z-index: 1;
  }

  iframe,
  video {
    width: 100% !important;
    height: 100% !important;
    margin: 0px;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    border: 0px;
  }

  @media (max-width: 600px) {
    display: none;
  }
`

const MobileVideoWrapper = styled.div`
  width: 100%;
  display: none;
  overflow: hidden;
  aspect-ratio: 246 / 313;

  > div {
    position: absolute;
    top: 0px;
    left: 0px;
    z-index: 1;
  }

  iframe,
  video {
    width: 100% !important;
    height: 100% !important;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    margin: 0px;
    border: 0px;
  }

  @media (max-width: 600px) {
    display: flex;
  }
`

const DesktopBannerImage = styled.img`
  width: 100%;
  margin: 0px;
  z-index: ${({ loading }) => (loading === "image" ? "2" : "1")};
`

const MobileBannerImage = styled.img`
  width: 100%;
  height: 100%;
  margin: 0px;
  z-index: ${({ loading }) => (loading === "image" ? "2" : "1")};
`

const TextWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: absolute;
  max-width: 1200px;
  margin: 0px auto;
  left: 0px;
  right: 0px;
  padding: 0px 20px;
  z-index: 1;
  top: 50%;
  transform: translate(0%, -50%);
  width: fit-content;

  @media (max-width: 600px) {
    display: none;
  }
`

const MobileTextWrapper = styled.div`
  width: 100%;
  display: none;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: absolute;
  max-width: 1200px;
  margin: 0px auto;
  left: 0px;
  right: 0px;
  padding: 0px 20px;
  z-index: 1;
  top: 50%;
  transform: translate(0%, -50%);
  width: fit-content;

  @media (max-width: 600px) {
    display: flex;
    width: 100%;
    top: 50%;
  }
`

const Title = styled.div`
  font-size: 50px;
  line-height: 1.2;
  color: #fff;
  font-family: "Museo";
  font-weight: 900;
  text-align: center;

  span {
    display: block;
    font-weight: bold;
  }

  @media (max-width: 850px) {
    font-size: 48px;
  }

  @media (max-width: 600px) {
    font-size: 32px;
  }

  @media (max-width: 350px) {
    font-size: 28px;
  }
`

const Button = styled.a`
  background: #f7f7f4;
  font-family: "Museo";
  font-weight: bold;
  cursor: pointer;
  text-align: center;
  color: #262626;
  font-size: 18px;
  padding: 15px 20px;
  line-height: 1;
  width: 100%;
  position: relative;
  max-width: 140px;
  margin: 30px 0px 0px;
  border: 1px solid #f7f7f4;

  :hover {
    color: #262626;
    background: #f7f7f4;
  }
`

const LogoBox = styled.div`
  display: flex;

  @media (max-width: 600px) {
    display: none;
  }
`

const MobileLogoBox = styled.div`
  display: none;

  @media (max-width: 600px) {
    display: flex;
  }
`

const LogoWrapper = styled.div`
  display: none;
  position: absolute;
  bottom: 20px;
  right: 20px;
  z-index: 1;

  @media (max-width: 600px) {
    bottom: 15px;
    right: 15px;
  }
`

const LogoImage = styled.img`
  width: 100%;
  height: 100%;
  max-width: 70px;
  margin: 0px 0px 0px 20px;

  @media (max-width: 600px) {
    max-width: 60px;
    margin-left: 15px;
  }
`

const LogoLyneImage = styled.img`
  width: 100%;
  height: 100%;
  max-width: 92px;
  margin: 0px 0px 0px 20px;

  @media (max-width: 600px) {
    max-width: 80px;
    margin-left: 15px;
  }
`

const MatelasHomeVideoBanner = ({ intl, data }) => {
  const [videoLoading, setVideoloading] = useState(true)

  const handleLoading = () => {
    setVideoloading(false)
  }

  const handleDesktopVideoData = event => {
    if (event.playedSeconds >= 5.9 && event.playedSeconds <= 16.6) {
      showSecondContent()
    } else {
      showFirstContent()
    }
  }

  const handleMobileVideoData = event => {
    if (event.playedSeconds >= 5.8 && event.playedSeconds <= 16.6) {
      showMobileSecondContent()
    } else {
      showMobileFirstContent()
    }
  }

  const showFirstContent = () => {
    document.getElementById("home_video_banner_link").href =
      "/eur/shop/solutions-textiles/"
    document.getElementById("home_video_banner_link3").href =
      "/eur/shop/solutions-textiles/"
    document.getElementById("home_video_banner_text").innerHTML =
      "Les T-shirts les plus recommandés par les médecins**"
    document.getElementById("home_video_matelas_banner_logo").style.display =
      "none"
    document.getElementById("home_video_lyne_banner_logo").style.display =
      "flex"
  }

  const showSecondContent = () => {
    document.getElementById("home_video_banner_link").href =
      "/eur/shop/matelas/"
    document.getElementById("home_video_banner_link3").href =
      "/eur/shop/matelas/"
    document.getElementById("home_video_banner_text").innerHTML =
      "Le meilleur matelas pour votre dos"
    document.getElementById("home_video_matelas_banner_logo").style.display =
      "flex"
    document.getElementById("home_video_lyne_banner_logo").style.display =
      "none"
  }

  const showMobileFirstContent = () => {
    document.getElementById("home_video_banner_mobile_link").href =
      "/eur/shop/solutions-textiles/"
    document.getElementById("home_video_banner_mobile_link3").href =
      "/eur/shop/solutions-textiles/"
    document.getElementById("home_video_banner_mobile_text").innerHTML =
      "Les T-shirts les plus recommandés par les médecins**"
    document.getElementById(
      "home_video_matelas_banner_mobile_logo"
    ).style.display = "none"
    document.getElementById(
      "home_video_lyne_banner_mobile_logo"
    ).style.display = "flex"
  }

  const showMobileSecondContent = () => {
    document.getElementById("home_video_banner_mobile_link").href =
      "/eur/shop/matelas/"
    document.getElementById("home_video_banner_mobile_link3").href =
      "/eur/shop/matelas/"
    document.getElementById("home_video_banner_mobile_text").innerHTML =
      "Le meilleur matelas pour votre dos"
    document.getElementById(
      "home_video_matelas_banner_mobile_logo"
    ).style.display = "flex"
    document.getElementById(
      "home_video_lyne_banner_mobile_logo"
    ).style.display = "none"
  }

  return (
    data && (
      <MatelasHomeVideoBannerWrapper>
        <TopSection className="HomeVideoBanner">
          {data.video_section_text.button_text &&
            data.video_section_text.button_url && (
              <AbsoluteButton
                href="/eur/shop/matelas/"
                id="home_video_banner_link3"
              >
                <ButtonTitle>{data.video_section_text.button_text}</ButtonTitle>
              </AbsoluteButton>
            )}
          {data.video_section_text.button_text &&
            data.video_section_text.button_url && (
              <MobileAbsoluteButton
                href="/eur/shop/matelas/"
                id="home_video_banner_mobile_link3"
              >
                <ButtonTitle>{data.video_section_text.button_text}</ButtonTitle>
              </MobileAbsoluteButton>
            )}
          <DesktopVideoWrapper>
            <DesktopBannerImage
              src="https://static.percko.com/uploads/c4a0f608-9c82-498b-8de0-68915c4b29c5.jpg"
              // src="https://static.percko.com/uploads/72940f68-cfe2-4e5a-ad63-0fd0a3e557ea.jpg"
              // src="https://static.percko.com/uploads/ca13dc9d-5101-4430-950c-5792e9a27ea1.jpeg"
              loading={videoLoading ? "image" : "video"}
            />

            <ReactPlayer
              url="https://www.percko.com/videos/percko.com/eur-fr/home-page/home_page_top_desktop_video.mp4"
              playing={true}
              controls={false}
              muted={true}
              loop={true}
              width="100%"
              height="100%"
              playsinline={true}
              playsInline
              progressInterval={200}
              onProgress={handleDesktopVideoData}
              onPlay={() => {
                handleLoading()
              }}
            />
          </DesktopVideoWrapper>
          <MobileVideoWrapper>
            <MobileBannerImage
              src="https://static.percko.com/uploads/f1d1f067-0f5b-4ffb-8c87-3e5436cc89b0.jpg"
              // src="https://static.percko.com/uploads/17c6f4b0-cbf2-44e1-9fa5-b0c22fd8c873.jpg"
              // src="https://static.percko.com/uploads/ce480624-d477-4fb5-b5b8-9151a8e5ac6c.jpg"
              loading={videoLoading ? "image" : "video"}
            />
            <ReactPlayer
              url="https://www.percko.com/videos/percko.com/eur-fr/home-page/home_page_top_mobile_video.mp4"
              playing={true}
              controls={false}
              muted={true}
              loop={true}
              width="100%"
              height="100%"
              playsinline={true}
              playsInline
              progressInterval={200}
              onProgress={handleMobileVideoData}
              onPlay={() => {
                handleLoading()
              }}
            />
          </MobileVideoWrapper>
          <TextWrapper lang={intl.locale}>
            {data.video_section_text.title && (
              <Title id="home_video_banner_text">
                Les T-shirts les plus recommandés par les médecins**
              </Title>
            )}
            {data.video_section_text.button_text &&
              data.video_section_text.button_url && (
                <Button
                  href="/eur/shop/solutions-textiles/"
                  id="home_video_banner_link"
                >
                  {data.video_section_text.button_text}
                </Button>
              )}
          </TextWrapper>
          <MobileTextWrapper lang={intl.locale}>
            {data.video_section_text.title && (
              <Title id="home_video_banner_mobile_text">
                Les T-shirts les plus recommandés par les médecins**
              </Title>
            )}
            {data.video_section_text.button_text &&
              data.video_section_text.button_url && (
                <Button
                  href="/eur/shop/solutions-textiles/"
                  id="home_video_banner_mobile_link"
                >
                  {data.video_section_text.button_text}
                </Button>
              )}
          </MobileTextWrapper>
          <LogoWrapper id="home_video_lyne_banner_logo">
            <LogoLyneImage
              className="lazyload"
              data-src="https://static.percko.com/uploads/0489aa9a-c6b0-44ea-a780-cc1bf57b7475.png"
            />
          </LogoWrapper>
          <LogoBox>
            <LogoWrapper id="home_video_matelas_banner_logo">
              <LogoImage
                className="lazyload"
                data-src="https://static.percko.com/uploads/6750140d-28da-4993-bf84-a00b42d0e0c6.jpg"
              />
              <LogoImage
                className="lazyload"
                data-src="https://static.percko.com/uploads/9faeb96f-d1ec-47cf-b812-bf9daab4692f.png"
              />
            </LogoWrapper>
          </LogoBox>
          <MobileLogoBox>
            <LogoWrapper id="home_video_lyne_banner_mobile_logo">
              <LogoLyneImage
                className="lazyload"
                data-src="https://static.percko.com/uploads/0489aa9a-c6b0-44ea-a780-cc1bf57b7475.png"
              />
            </LogoWrapper>
            <LogoWrapper id="home_video_matelas_banner_mobile_logo">
              <LogoImage
                className="lazyload"
                data-src="https://static.percko.com/uploads/6750140d-28da-4993-bf84-a00b42d0e0c6.jpg"
              />
              <LogoImage
                className="lazyload"
                data-src="https://static.percko.com/uploads/9faeb96f-d1ec-47cf-b812-bf9daab4692f.png"
              />
            </LogoWrapper>
          </MobileLogoBox>
        </TopSection>
      </MatelasHomeVideoBannerWrapper>
    )
  )
}

export default injectIntl(MatelasHomeVideoBanner)
